<template>
    <div class="card">
        <div class="flex align-items-center justify-content-start">
            <img :src="'/assets/image/carousel.png'" class="max-w-full" alt="画像なし">
        </div>
        <div class="mx-5 my-4 text-left">
            <p class="text-700">当アプリケーションは各種便利な「ツールサービス」を提供しています。</p>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <div v-for="(menu) of $Menus" :key="menu.code">
                <div class="flex flex-column my-5 py-5">
                    <div class="flex align-items-center justify-content-start h-4rem bg-red-600 text-white text-3xl font-bold border-round mx-2 my-4 px-4 gap-2">
                        <img :src="'/assets/image/subject_48.png'" alt="画像なし">{{menu.name}}
                    </div>
                    <div class="flex flex-wrap md:flex-wrap">
                        <div v-for="(item) of menu.items" :key="item.code">
                            <div class="flex align-items-center justify-content-center tool-item-box"
                                style="min-width: 200px; min-height: 60px" @click="onClickItem(item.code)">
                                <img :src="'/assets/image/tool-menu.png'" alt="画像なし">
                                <p class="text-xl px-2">{{item.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            UxSeparator,
            UxFooterPanel
        },
        props: {
        },
        data() {
            return {
            };
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "メニュー選択"イベント時処理
             */
            onClickItem(id) {
                HtmlUtils.movePage(id ? `./?page=${id}` : `./`, '_self');
            }
        }
    };
</script>

<style scoped>
.tool-item-box{
    position: relative;
    background: #333;
    margin: 1em 10px;
    padding: 1em;
    border: 8px solid #b3b3b3;
    box-shadow: 2px 2px 4px #999, 2px 2px 2px #020 inset;
    cursor: pointer;
}

.tool-item-box:before {
    position: absolute;
    top: calc(-24px);
    left: 0px;
    height: 14px;
    width: calc(100% - 4px);
    background-color: #b3b3b3;
    border-top: 2px solid #666;
    border-left: 2px solid #666;
    border-right: 2px solid #666;
    content: "";
}

.tool-item-box:hover{
    background: #666;
}

.tool-item-box p{
    margin: 0;
    padding: 0;
    color: #fff;
    text-shadow: 0 0 2px #fff;
    user-select: none;
}
</style>