/**
 * JSON整形サービス
 */
export default class CleanJsonService {

    /**
     * JSON整形
     * @param {String} value 値
     * @returns {String} 整形後値
     */
    async cleanJson(value) {
        let json = JSON.parse(value);
        let cleand = JSON.stringify(json, null, 4);
        return cleand;
    }

    /**
     * JSON圧縮
     * @param {String} value 値
     * @returns {String} 圧縮後値
     */
    async minifyJson(value='') {
        let json = JSON.parse(value);
        let minified = JSON.stringify(json);
        return minified;
    }

};
