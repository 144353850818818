/**
 * JPG 変換サービス
 */
export default class ConvertJpgService {

    /**
     * JPG to PNG
     * @param {File} file 画像ファイル
     * @returns {Buffer} 変換結果バッファ
     */
    async convertJpgToPng(file) {
        const result = await new Promise((resolve, reject) => {
            try {
                // 読込
                const reader = new FileReader();
                reader.onload = function(event) {
                    const buffer = event.target.result;
                    // 変換
                    const img = new Image();
                    img.onload = function() {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.width;
                        canvas.height = img.height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);

                        // CanvasからJPEGを生成します。
                        canvas.toBlob(function(blob) {
                            resolve(blob);
                        }, 'image/png');
                    };
                    img.src = buffer;
                };
                reader.readAsDataURL(file);
            } catch (error) {
                reject(error);
            }
        });
        return result;
    }

};
