<template>
    <div class="workspace-panel card flex justify-content-center">
        <ExWorkspace :visible="visible" :modal="modal" :position="position" style="position: fixed; top:60px; height: calc(100vh - 60px);" >
            <slot name="content"></slot>
        </ExWorkspace>
    </div>
</template>

<script>
    import ExWorkspace from "@/overrides/frame/ExWorkspace"

    export default {
        components: {
            ExWorkspace
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            position: {
                type: String,
                default: 'right'
            },
            modal: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped>
    .workspace-panel {
        display: visible;
    }
</style>
