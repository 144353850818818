/**
 * PNG 変換サービス
 */
export default class ConvertPngService {

    /**
     * PNG to JPG
     * @param {File} file 画像ファイル
     * @param {Number} quality JPEGの品質（0.8は80%の品質）
     * @returns {Buffer} 変換結果バッファ
     */
    async convertPngToJpg(file, quality=1.0) {
        const result = await new Promise((resolve, reject) => {
            try {
                // 読込
                const reader = new FileReader();
                reader.onload = function(event) {
                    const buffer = event.target.result;
                    // 変換
                    const img = new Image();
                    img.onload = function() {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.width;
                        canvas.height = img.height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);

                        // CanvasからJPEGを生成します。
                        canvas.toBlob(function(blob) {
                            resolve(blob);
                        }, 'image/jpeg', quality);
                    };
                    img.src = buffer;
                };
                reader.readAsDataURL(file);
            } catch (error) {
                reject(error);
            }
        });
        return result;
    }

    /**
     * PNG 透過
     * @param {File} buffer 画像ファイルバッファ
     * @param {Object} color 色（r,g,b）※指定なしは左上のビット色
     * @param {Number} errorRange RGB（255）の誤差
     * @returns {Buffer} 変換結果バッファ
     */
    async convertPngToTransparent(buffer, color={}, errorRange=50) {
        const result = await new Promise((resolve, reject) => {
            try {
                // 変換
                const img = new Image();
                img.onload = function() {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    // 画像データを取得
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;

                    // 背景色の取得（ここでは左上ピクセルの色を背景色と仮定）
                    const r0 = (color||{}).r||data[0];
                    const g0 = (color||{}).g||data[1];
                    const b0 = (color||{}).b||data[2];

                    // 背景色を透過色に変更
                    for (let i = 0; i < data.length; i += 4) {
                        const r = data[i];
                        const g = data[i + 1];
                        const b = data[i + 2];

                        // 背景色と一致する場合はアルファ値を0に
                        if (((r0 - errorRange) <= r && r <= (r0 + errorRange))
                        && ((g0 - errorRange) <= g && g <= (g0 + errorRange))
                        && ((b0 - errorRange) <= b && b <= (b0 + errorRange))) {
                            data[i + 3] = 0; // アルファ値を0に
                        }
                    }

                    // 透過したデータをCanvasに反映
                    ctx.putImageData(imageData, 0, 0);

                    // CanvasからPNGを生成します。
                    canvas.toBlob(function(blob) {
                        resolve(blob);
                    }, 'image/png');
                };
                img.src = buffer;
            } catch (error) {
                reject(error);
            }
        });
        return result;
    }

    /**
     * PNG 色変更
     * @param {File} buffer 画像ファイルバッファ
     * @param {Object} fromColor 変更前の色（r,g,b）※指定なしは左上のビット色
     * @param {Object} toColor 変更後の色（r,g,b）※指定なしは左上のビット色
     * @param {Number} errorRange RGB（255）の誤差
     * @returns {Buffer} 変換結果バッファ
     */
    async convertPngChangeColor(buffer, fromColor={}, toColor={}, errorRange=50) {
        const result = await new Promise((resolve, reject) => {
            try {
                // 変換
                const img = new Image();
                img.onload = function() {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    // 画像データを取得
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;

                    // 背景色の取得（ここでは左上ピクセルの色を背景色と仮定）
                    const fromR0 = (fromColor||{}).r||data[0];
                    const fromG0 = (fromColor||{}).g||data[1];
                    const fromB0 = (fromColor||{}).b||data[2];
                    const toR0 = (toColor||{}).r||data[0];
                    const toG0 = (toColor||{}).g||data[1];
                    const toB0 = (toColor||{}).b||data[2];

                    // 色変更
                    for (let i = 0; i < data.length; i += 4) {
                        const r = data[i];
                        const g = data[i + 1];
                        const b = data[i + 2];

                        // 色変更
                        if (((fromR0 - errorRange) <= r && r <= (fromR0 + errorRange))
                        && ((fromG0 - errorRange) <= g && g <= (fromG0 + errorRange))
                        && ((fromB0 - errorRange) <= b && b <= (fromB0 + errorRange))) {
                            data[i] = toR0;
                            data[i + 1] = toG0;
                            data[i + 2] = toB0;
                        }
                    }

                    // 変更したデータをCanvasに反映
                    ctx.putImageData(imageData, 0, 0);

                    // CanvasからPNGを生成します。
                    canvas.toBlob(function(blob) {
                        resolve(blob);
                    }, 'image/png');
                };
                img.src = buffer;
            } catch (error) {
                reject(error);
            }
        });
        return result;
    }
};
