import { css as beautifyCss } from 'js-beautify';
import { minify as minifyCsso } from 'csso';

/**
 * CSS整形サービス
 */
export default class CleanCssService {

    /**
     * CSS整形
     * @param {String} value 値
     * @returns {String} 整形後値
     */
    async cleanCss(value='') {
        // CSSを整形
        const cleand = beautifyCss(value, {
            indent_size: 4,  // インデントのサイズ
            preserve_newlines: true,  // 改行を保持する
        });
        return cleand;
    }

    /**
     * CSS圧縮
     * @param {String} value 値
     * @returns {String} 圧縮後値
     */
    async minifyCss(value='') {
        const minified = minifyCsso(value).css;
        // const minified = new CleanCSS({
        // }).minify(value);
        // return minified;
        return minified;
    }

};
