<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'PNG透過'" :desc="'PNG 画像の指定の色を「透過」することができます。'"/>

            <div class="my-5 py-5">
                <div v-if="previewImage != null" class="card flex justify-content-between py-2">
                    <div class="card flex justify-content-start gap-2 py-2 px-2">
                        <Button type="button" label="ダウンロード" icon="pi pi-download" @click="onDownload" severity="secondary" outlined />
                        <Button type="button" label="再実行" icon="pi pi-sync" @click="onExecute" severity="secondary" outlined />
                        <Button type="button" label="リセット" icon="pi pi-undo" @click="onReset" severity="secondary" outlined />
                    </div>
                </div>
                <div v-if="previewImage != null" class="card py-2">
                    <div class="card flex justify-content-start gap-2 py-2 px-2">
                        <span class="w-4rem mt-2">色：</span>
                        <ColorPicker class="mt-1" v-model="color" inputId="color" format="rgb" />
                        <InputNumber v-model="error" inputId="error" :min="0" :max="256" mode="decimal" placeholder="誤差（0~255）" />
                    </div>
                </div>

                <div v-if="previewImage != null">
                    <div class="card flex align-items-center justify-content-between p-5 border-3 border-500">
                        <img :src="previewImage" class="max-w-full" alt="画像なし"
                        style="background: url('/assets/image/checkered.jpg');background-repeat: repeat">
                    </div>
                    <div class="card flex justify-content-start gap-2 py-2 px-2">
                        <div class="text-lg">{{previewName}}</div>
                    </div>
                </div>

                <div v-if="previewImage == null">
                    <FileUpload
                        accept="image/png"
                        :maxFileSize="maxFileSize"
                        :auto="true"
                        @select="onSelect"
                        >
                        <template #header="{ chooseCallback }">
                            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                                <div class="flex gap-2">
                                    <Button type="button" label="選択" icon="pi pi-file" @click="chooseCallback" severity="primary" outlined />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            <div class="flex align-items-center justify-content-center flex-column">
                                <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                                <p class="mt-4 mb-0">画像ファイルをドラッグ＆ドロップまたは選択してください。</p>
                            </div>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxSitemapPanel/>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxSitemapPanel from '@/ux/panel/UxSitemapPanel';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';
    import ColorPicker from 'primevue/colorpicker';
    import InputNumber from 'primevue/inputnumber';
    import FileUpload from 'primevue/fileupload';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxSitemapPanel,
            UxFooterPanel,
            Button,
            ColorPicker,
            InputNumber,
            FileUpload
        },
        props: {
            maxFileSize: {
                type: Number,
                default: 1000000
            }
        },
        data() {
            return {
                imageOrign: null,
                previewImage: null,
                previewName: null,
                color: {r: 0, g: 0, b: 0},
                error: 50,
            };
        },
        computed: {
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {

            /**
             * "ファイルアップロード"イベント時処理
             */
            onSelect(event) {
                const me = this;
                const file = event.files[0];

                // ファイル存在判定
                if (!file) {
                    return;
                }

                // 画像退避
                me.imageOrign = file;

                // プレヴュー配置
                const reader = new FileReader();
                reader.onload = function(event) {
                    const buffer = event.target.result;
                    me.previewImage = buffer;
                    me.previewName = me.imageOrign.name;
                };
                reader.readAsDataURL(file);
            },

            /**
             * "ダウンロード"イベント時処理
             */
            onDownload() {
                HtmlUtils.download(this.previewImage, this.previewName);
            },

            /**
             * "再実行"イベント時処理
             */
            onExecute() {
                const me = this;

                // 変換
                me.$Service.ConvertPngService.convertPngToTransparent(me.previewImage, me.color, me.error).then(function(result) {
                    me.previewImage = URL.createObjectURL(result);
                    me.previewName = me.imageOrign.name;
                });
            },

            /**
             * "リセット"イベント時処理
             */
            onReset() {
                this.previewImage = null;
            }
        }
    };
</script>

<style scoped>
</style>