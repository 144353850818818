<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'グラデーション生成'" :desc="'指定された２色からグラデーションの生成を行うことができます。'"/>

            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="変換" icon="pi pi-pencil" @click="onConvert" severity="secondary" outlined />
                    <Button type="button" label="リセット" icon="pi pi-undo" @click="onReset" severity="secondary" outlined />
                </div>
            </div>

            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <span class="w-5rem mt-2" :style="{ textAlign: 'left' }">色１：</span>
                    <ColorPicker class="mt-1" v-model="color1" inputId="color1" format="hex" />
                    <span class="text-3xl mt-1 mx-2">#</span><InputText type="text" v-model="color1" placeholder="16進数" />
                </div>
            </div>
            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <span class="w-5rem mt-2" :style="{ textAlign: 'left' }">色２：</span>
                    <ColorPicker class="mt-1" v-model="color2" inputId="color2" format="hex" />
                    <span class="text-3xl mt-1 mx-2">#</span><InputText type="text" v-model="color2" placeholder="16進数" />
                </div>
            </div>
            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <span class="w-5rem mt-2" :style="{ textAlign: 'left' }">分割数：</span>
                    <InputNumber v-model="steps" inputId="steps" :min="0" :max="30" mode="decimal" placeholder="分割数" />
                </div>
            </div>

            <div class="card py-2">
                <div v-for="(color) of gradation" :key="color">
                    <div class="flex align-items-center justify-content-center font-bold border-500 border-1"
                        :style="{ width: '100%', minHeight: '40px', background: color, textShadow: '-1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff' }">
                        {{ color }}
                    </div>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxSitemapPanel/>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxSitemapPanel from '@/ux/panel/UxSitemapPanel';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';
    import ColorPicker from 'primevue/colorpicker';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxSitemapPanel,
            UxFooterPanel,
            Button,
            ColorPicker,
            InputText,
            InputNumber
        },
        props: {
        },
        data() {
            return {
                color1: null,
                color2: null,
                steps: 5,
                gradation: []
            };
        },
        computed: {
        },
        mounted() {
            this.onReset();
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {

            /**
             * "変換"イベント時処理
             */
            onConvert() {
                const me = this;

                // RGB取得
                const r1 = parseInt(me.color1.substring(0, 2), 16);
                const g1 = parseInt(me.color1.substring(2, 4), 16);
                const b1 = parseInt(me.color1.substring(4, 6), 16);
                const r2 = parseInt(me.color2.substring(0, 2), 16);
                const g2 = parseInt(me.color2.substring(2, 4), 16);
                const b2 = parseInt(me.color2.substring(4, 6), 16);

                // 使用する色とステップ数
                const color1 = [r1, g1, b1];
                const color2 = [r2, g2, b2];
                const steps = me.steps;

                function interpolateColor(color1, color2, factor) {
                    if (arguments.length < 3) {
                        factor = 0.5;
                    }
                    let result = color1.slice();
                    for (let i = 0; i < 3; i++) {
                        result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
                    }
                    return result;
                }

                function interpolateColors(color1, color2, steps) {
                    let stepFactor = 1 / (steps - 1),
                        interpolatedColorArray = [];

                    for(let i = 0; i < steps; i++) {
                        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
                    }

                    return interpolatedColorArray;
                }

                function rgbToHex(r, g, b) {
                    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
                }

                const gradient = [];
                const colors = interpolateColors(color1, color2, steps);

                colors.forEach(color => {
                    const rgb = rgbToHex(color[0], color[1], color[2]);
                    gradient.push(rgb);
                });
                me.gradation = gradient;
            },

            /**
             * "リセット"イベント時処理
             */
            onReset() {
                this.color1 = '000000';
                this.color2 = 'ffffff';
                this.onConvert('color');
            }
        }
    };
</script>

<style scoped>
</style>