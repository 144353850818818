<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'商用フリー画像'" :desc="'商用フリー画像を取り扱うサイトをまとめています。'"/>

            <div class="card my-2">
                <div v-for="(link) of links" :key="link.href">
                    <a :href="link.href" class="link-card p-card p-shadow-2 no-underline">
                        <div class="flex overflow-hidden my-5 py-2 px-2 border-1 border-round">
                            <div class="flex-none flex align-items-center justify-content-center">
                                <iframe
                                    style="width:200px;height:100%;max-width:200px;"
                                    :title="link.title"
                                    :src="link.href"
                                    frameborder="0" scrolling="no" allow="fullscreen">
                                </iframe>
                            </div>
                            <div class="flex-grow-1 flex align-items-center justify-content-start">
                                <div class="card w-full">
                                    <div class="flex flex-wrap">
                                        <p class="text-2xl text-overflow-ellipsis mt-2 ml-3">{{link.title}}</p>
                                    </div>
                                    <div class="flex flex-wrap">
                                        <p class="text-lg white-space-normal text-overflow-ellipsis mt-2 ml-5" style="word-break: break-all;">{{link.desc}}</p>
                                    </div>
                                    <div class="flex align-items-end justify-content-end">
                                        {{'移動 >'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxSitemapPanel/>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxSitemapPanel from '@/ux/panel/UxSitemapPanel';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxSitemapPanel,
            UxFooterPanel
        },
        props: {
        },
        data() {
            return {
                links: [
                    {
                        href: 'https://o-dan.net/ja/',
                        title: 'O-DAN (オーダン）- 無料写真素材・フリーフォト検索',
                        desc: '高品質な無料写真素材・フリーフォトを40以上の有名ストックフォトサイトから日本語で横断検索できるサービス「O-DAN(オーダン)」'
                    },
                    {
                        href: 'https://www.irasutoya.com/',
                        title: 'かわいいフリー素材集 いらすとや',
                        desc: '無料で使えるかわいいイラストの素材集です。個人利用・商用利用ともに完全無料。季節のイベントのイラストや動物や子供のイラストなど、使いやすいイラストが盛りだくさん。透過PNG形式で、組み合わせも簡単です。'
                    },
                    {
                        href: 'https://www.pakutaso.com/',
                        title: 'ぱくたそ 無料の写真素材・AI画像素材',
                        desc: '「ぱくたそ」は、会員登録せずに今すぐダウンロードできる無料の写真素材・AI画像素材のフリー素材サイトです。一部を有料販売したり、枚数制限による課金など一切ありません。美しい日本の風景を中心に人物やテクスチャーなどの写真とAIで生成した背景画像の素材を配布しています。2024年7月23日現在、54,265枚の写真を掲載中。'
                    },
                    {
                        href: 'https://www.photo-ac.com/',
                        title: '写真素材なら「写真AC」無料（フリー）ダウンロードOK',
                        desc: '無料写真素材を提供する「写真AC」のフリー写真素材は、個人、商用を問わず無料でお使いいただけます。クレジット表記やリンクは一切不要です。Web、DTP、動画などの写真素材としてお使いください。'
                    },
                    {
                        href: 'https://icon-rainbow.com/',
                        title: 'icon-rainbow',
                        desc: '商用利用可能なアイコンを即刻ダウンロードできるアイコンサイトです。ダウンロードした素材は、WEBページやアプリのアイコン、DTPデザインなどに使用可能!!'
                    }
                ]
            };
        },
        computed: {
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
        }
    };
</script>

<style scoped>
</style>