<template>
    <!-- メニューバー -->
    <MainMenubar
        v-on:logo="onLogo"
        v-on:navigation="onNavigation"
        v-on:changemode="onChangeMenuOption"
        v-on:workspace="onWorkspace"
        ref="menuPanel"/>

    <!-- ナビゲーション -->
    <MainNavigation
        :visible="navigationVisible"
        v-on:close="onCloseNavigation"
        v-on:select-item="onSelectNavigationItem"
        ref="navigationPanel"/>

    <!-- ワークスペース -->
    <MainWorkspace
        :visible="workspaceVisible"
        :menuSelectedMode="menuSelectedMode"
        v-on:select-menu="onChangeWorkspaceMenuOption"
        ref="workspacePanel"/>

    <!-- メインパネル -->
    <MainMainPanel
        :menuSelectedMode="workspaceMenuSelectedMode"
        ref="mainPanel"/>

    <router-view />
</template>

<script>
    import MainMenubar from "@/views/main/MainMenubar";
    import MainNavigation from "@/views/main/MainNavigation";
    import MainWorkspace from "@/views/main/MainWorkspace";
    import MainMainPanel from "@/views/main/MainMainPanel";

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            MainMenubar,
            MainNavigation,
            MainWorkspace,
            MainMainPanel
        },
        data() {
            return {
                navigationVisible: false,
                workspaceVisible: false,
                menuSelectedMode: null,
                workspaceMenuSelectedMode: null,
            };
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
            // リクエストパラメータ処理
            this.workspaceMenuSelectedMode = HtmlUtils.getRequestParameter('page');
        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        watch: {
        },
        computed: {
        },
        methods: {
            /**
             * "ロゴ"ボタン押下時処理
             */
            onLogo() {
                this.onChangeWorkspaceMenuOption(null);
            },

            /**
             * "ナビゲーション"イベント時処理
             */
            onNavigation: function() {
                this.navigationVisible = !this.navigationVisible;
            },

            /**
             * "ナビゲーション閉じる"イベント時処理
             */
            onCloseNavigation: function() {
                this.navigationVisible = false;
            },

            /**
             * "ナビゲーション項目選択"イベント時処理
             */
            onSelectNavigationItem: function(itemId) {
                switch (itemId) {
                    default:
                        break;
                }
            },

            /**
             * "メニューオプション変更"イベント時処理
             */
            onChangeMenuOption(mode) {
                this.menuSelectedMode = mode;
                this.workspaceVisible = (this.menuSelectedMode !== null);
            },

            /**
             * "ワークスペースメニューオプション変更"イベント時処理
             */
            onChangeWorkspaceMenuOption(mode) {
                this.workspaceMenuSelectedMode = mode;
                this.$refs.menuPanel.menuSelectedMode = null;
                this.menuSelectedMode = null;
                this.workspaceVisible = false;
                HtmlUtils.setRequestParameter('page', mode);
                HtmlUtils.movePage(mode ? `./?page=${mode}` : `./`, '_self');
            },

            /**
             * "ワークスペース"ボタン押下時処理
             */
            onWorkspace() {
                this.workspaceVisible = !this.workspaceVisible;
            }
        }
    };
</script>

<style scoped>
</style>