/**
 * SVG 変換サービス
 */
export default class ConvertSvgService {

    /**
     * SVG to PNG
     * @param {File} file 画像ファイル
     * @param {Number} width 幅
     * @param {Number} height 高さ
     * @returns {Buffer} 変換結果バッファ
     */
    async convertSvgToPng(file, width=null, height=null) {
        const result = await new Promise((resolve, reject) => {
            try {
                // 読込
                const reader = new FileReader();
                reader.onload = function(event) {
                    const svgString = event.target.result;
                    // 変換
                    const img = new Image();
                    img.onload = function() {
                        const canvas = document.createElement('canvas');
                        canvas.width = width||img.width;
                        canvas.height = height||img.height;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);

                        // CanvasからPNGを生成します。
                        canvas.toBlob(function(blob) {
                            resolve(blob);
                        }, 'image/png');
                    };
                    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
                };
                reader.readAsText(file);
            } catch (error) {
                reject(error);
            }
        });
        return result;
    }

    /**
     * SVG to JPG
     * @param {File} file 画像ファイル
     * @param {Number} width 幅
     * @param {Number} height 高さ
     * @param {Number} quality JPEGの品質（0.8は80%の品質）
     * @returns {Buffer} 変換結果バッファ
     */
    async convertSvgToJpg(file, width=null, height=null, quality=1.0) {
        const result = await new Promise((resolve, reject) => {
            try {
                // 読込
                const reader = new FileReader();
                reader.onload = function(event) {
                    const svgString = event.target.result;
                    // 変換
                    const img = new Image();
                    img.onload = function() {
                        const canvas = document.createElement('canvas');
                        canvas.width = width||img.width;
                        canvas.height = height||img.height;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);

                        // CanvasからPNGを生成します。
                        canvas.toBlob(function(blob) {
                            resolve(blob);
                        }, 'image/jpeg', quality);
                    };
                    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
                };
                reader.readAsText(file);
            } catch (error) {
                reject(error);
            }
        });
        return result;
    }

};
