<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'RGB／16進数変換'" :desc="'「RGB」と「16進数」の変換を行うことができます。'"/>

            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="リセット" icon="pi pi-undo" @click="onReset" severity="secondary" outlined />
                </div>
            </div>

            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <span class="w-5rem mt-2" :style="{ textAlign: 'left' }">色：</span>
                    <ColorPicker class="mt-1" v-model="color" inputId="color" format="hex" @change="onConvert('color')" />
                </div>
            </div>
            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <span class="w-5rem mt-2" :style="{ textAlign: 'left' }">16進数：</span>
                    <InputText type="text" v-model="hex" placeholder="16進数" @update:modelValue="onConvert('hex')" />
                </div>
            </div>
            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <span class="w-5rem mt-2" :style="{ textAlign: 'left' }">RGB：</span>
                    <InputNumber v-model="r" inputId="color-r" :min="0" :max="256" mode="decimal" placeholder="R" @update:modelValue="onConvert('rgb')" />
                    <InputNumber v-model="g" inputId="color-g" :min="0" :max="256" mode="decimal" placeholder="G" @update:modelValue="onConvert('rgb')" />
                    <InputNumber v-model="b" inputId="color-b" :min="0" :max="256" mode="decimal" placeholder="B" @update:modelValue="onConvert('rgb')" />
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxSitemapPanel/>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxSitemapPanel from '@/ux/panel/UxSitemapPanel';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';
    import ColorPicker from 'primevue/colorpicker';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxSitemapPanel,
            UxFooterPanel,
            Button,
            ColorPicker,
            InputText,
            InputNumber
        },
        props: {
        },
        data() {
            return {
                color: null,
                hex: null,
                r: null,
                g: null,
                b: null
            };
        },
        computed: {
        },
        mounted() {
            this.onReset();
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {

            /**
             * "変換"イベント時処理
             */
            onConvert(type) {
                const me = this;
                switch (type) {
                case 'color':
                    {
                        me.hex = `#${me.color}`;
                        const r = me.color.substring(0, 2);
                        const g = me.color.substring(2, 4);
                        const b = me.color.substring(4, 6);
                        me.r = parseInt(r, 16);
                        me.g = parseInt(g, 16);
                        me.b = parseInt(b, 16);
                    }
                    break;
                case 'hex':
                    {
                        me.color = me.hex.replace('#', '');
                        const r = me.color.substring(0, 2);
                        const g = me.color.substring(2, 4);
                        const b = me.color.substring(4, 6);
                        me.r = parseInt(r, 16);
                        me.g = parseInt(g, 16);
                        me.b = parseInt(b, 16);
                    }
                    break;
                case 'rgb':
                    {
                        const r = (me.r||0).toString(16).padStart(2, '0');
                        const g = (me.g||0).toString(16).padStart(2, '0');
                        const b = (me.b||0).toString(16).padStart(2, '0');
                        me.color = `${r}${g}${b}`;
                        me.hex = `#${me.color}`;
                    }
                    break;
                default:
                    break;
                }
            },

            /**
             * "リセット"イベント時処理
             */
            onReset() {
                this.color = 'ffffff';
                this.onConvert('color');
            }
        }
    };
</script>

<style scoped>
</style>