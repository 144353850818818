import { js as beautifyJs } from 'js-beautify';
import { minify as minifyJs } from 'terser';

/**
 * JS整形サービス
 */
export default class CleanCssService {

    /**
     * JS整形
     * @param {String} value 値
     * @returns {String} 整形後値
     */
    async cleanJs(value='') {
        // CSSを整形
        const cleand = beautifyJs(value, {
            indent_size: 4,  // インデントのサイズ
            preserve_newlines: true,  // 改行を保持する
            space_in_paren: true,  // 括弧内にスペースを入れる
            jslint_happy: true  // JSLintの好みに合わせる
        });
        return cleand;
    }

    /**
     * JS圧縮
     * @param {String} value 値
     * @returns {String} 圧縮後値
     */
    async minifyJs(value='') {
        const minified = await minifyJs(value);
        return minified.code;
    }

};
