<template>
    <UxMenubar :menuItems="menuItems" :visible="true">
        <template #start>
            <div class="card flex justify-content-center gap-2">
                <Button v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onNavigation" severity="secondary" outlined/>
                <UxLogo :src="'/assets/image/logo.png'" :height="'30'" class="cursor-pointer mt-1" @click="onLogo" />
            </div>
        </template>
        <template #end>
            <div class="card flex justify-content-center gap-2">
                <MqResponsive target="xs-">
                    <SelectButton v-model="menuSelectedMode" :options="menuModeOptionsForSmall" dataKey="value" optionValue="value" optionLabel="name" aria-labelledby="basic" style="display:inline-block !important;" @click="onChangeMenuOption">
                        <template #option="slotProps">
                            <span style="height:24px"><i :class="slotProps.option.icon" v-tooltip.bottom="slotProps.option.name"></i></span>
                        </template>
                    </SelectButton>
                </MqResponsive>
                <MqResponsive target="sm+">
                    <SelectButton v-model="menuSelectedMode" :options="menuModeOptions" dataKey="value" optionValue="value" optionLabel="name" aria-labelledby="basic" style="display:inline-block !important;" @click="onChangeMenuOption">
                        <template #option="slotProps">
                            <span class="w-5rem"><i :class="slotProps.option.icon"></i><span class="px-1"></span>{{slotProps.option.name}}</span>
                        </template>
                    </SelectButton>
                </MqResponsive>
            </div>
        </template>
    </UxMenubar>
</template>

<script>
    import { MqResponsive } from "vue3-mq";

    import Button from "primevue/button"
    import SelectButton from "primevue/selectbutton"

    import UxMenubar from "@/ux/frame/UxMenubar"
    import UxLogo from "@/ux/field/UxLogo"

    export default {
        components: {
            MqResponsive,
            Button,
            SelectButton,
            UxMenubar,
            UxLogo
        },
        props: {
        },
        emits: ['logo','navigation','workspace','navigation','changemode'],
        data() {
            return {
                menuSelectedMode: null,
                menuModeOptions: [
                    // { name: '整形', icon: 'pi pi-pencil', value: 'clean' },
                    // { name: '加工', icon: 'pi pi-images', value: 'image' },
                ],
                menuModeOptionsForSmall: [
                    // { name: '整形', icon: 'pi pi-pencil', value: 'clean' },
                    // { name: '加工', icon: 'pi pi-images', value: 'image' },
                ],
                menuItems: []
            };
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            /**
             * "ロゴ"ボタン押下時処理
             */
            onLogo() {
                this['$emit']('logo');
            },

            /**
             * "ナビゲーション"ボタン押下時処理
             */
            onNavigation() {
                this['$emit']('navigation');
            },

            /**
             * "メニューオプション変更"イベント時処理
             */
            onChangeMenuOption() {
                this['$emit']('changemode', this.menuSelectedMode);
            }
        }
    };
</script>

<style scoped>
</style>
