<template>
    <div>
        <div class="flex align-items-center justify-content-start h-4rem bg-red-600 text-white text-3xl font-bold border-round my-2 px-4">{{title}}</div>
        <div class="card flex justify-content-start">
            <p class="px-2 text-secondary">{{desc}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            desc: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style scoped>
</style>
