<template>
  <h1>About</h1>
</template>

<script>
  export default {
    name: 'AboutPage',
    components: {
    },
    props: {
    },
    methods: {
      onClick() {
        console.log('Button clicked');
      },
    }
  }
</script>
