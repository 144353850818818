import xmlFormat from 'xml-formatter';

/**
 * XML整形サービス
 */
export default class CleanXmlService {

    /**
     * XML整形
     * @param {String} value 値
     * @returns {String} 整形後値
     */
    async cleanXml(value='') {
        const cleand = xmlFormat(value, {
        });
        return cleand;
    }

    /**
     * XML圧縮
     * @param {String} value 値
     * @returns {String} 圧縮後値
     */
    async minifyXml(value='') {
        const minified = xmlFormat.minify(value, {
        });
        return minified;
    }

};
