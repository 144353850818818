<script>
    import Sidebar from "primevue/sidebar"

    export default {
        extends: Sidebar,
        name: 'ex-sidebar',
        render: Sidebar.render,
        props: {
            showCloseIcon: {
                default: false
            }
        }
    };
</script>

<style scoped>
    .p-sidebar {
        display: visible;
        margin: 0px !important;
        padding: 0px !important;
        /* 高さ固定 */
        /*
        min-height: calc(100vh - 60px);
        max-height: calc(100vh - 60px);
        */
    }
    .p-sidebar-header {
        display: none !important;
        height: 60px;
        justify-content: flex-start;
    }
    .p-sidebar-content {
        display: visible;
        margin: 0px !important;
        padding: 0px !important;
    }
</style>
