<template>
    <UxWorkspace :visible="visible">
        <template #content>
            <div class="card h-full">
                <DataTable :value="menus" dataKey="code"
                    scrollable scrollHeight="auto" @row-click="onSelectMenu">
                    <Column field="name"></Column>
                </DataTable>
            </div>
        </template>
    </UxWorkspace>
</template>

<script>
    import UxWorkspace from "@/ux/frame/UxWorkspace";

    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    export default {
        components: {
            UxWorkspace,
            DataTable,
            Column
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            menuSelectedMode: {
                type: String,
                default: null
            }
        },
        data() {
            return {
            };
        },
        watch: {
        },
        computed: {
            menus() {
                const group = this.menuSelectedMode;
                const menus =  [];
                const root = this.$Menus.filter(v => v.code === group);
                if (root.length == 0) {
                    return menus;
                }
                for (const item of root[0].items) {
                    menus.push({ group: group, code: item.code, name: item.name });
                }
                return menus;
            }
        },
        emits: ['select-menu'],
        methods: {

            /**
             * "メニュー選択"イベント時処理
             */
            onSelectMenu(event) {
                this['$emit']('select-menu', event.data.code);
            }
        }
    };
</script>

<style scoped>
</style>