<template>
    <div>
        <div class="flex align-items-center justify-content-start h-3rem bg-red-600 text-white text-2xl font-bold border-round my-2 px-4">関連サービス</div>
        <div v-for="(menu) of $Menus" :key="menu.code">
            <div class="flex flex-column">
                <div class="flex align-items-center justify-content-start h-2rem text-xl font-bold border-round m-2 px-2">{{menu.name}}</div>
                <div class="flex flex-wrap md:flex-wrap">
                    <div v-for="(item) of menu.items" :key="item.code">
                        <!-- <div class="flex align-items-center justify-content-center font-bold m-2 border-500 surface-overlay border-3 hover:border-red-600"
                            style="min-width: 160px; min-height: 40px" @click="onClickItem(item.code)">
                            <div class="text-md">{{item.name}}</div>
                        </div> -->
                        <div class="flex align-items-center justify-content-center tool-item-box"
                            style="min-width: 160px; min-height: 40px" @click="onClickItem(item.code)">
                            <img :src="'/assets/image/tool-menu-small.png'" alt="画像なし">
                            <p class="text-md px-2">{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
            };
        },
        computed: {
        },
        emits: [],
        methods: {
            /**
             * "メニュー選択"イベント時処理
             */
            onClickItem(id) {
                HtmlUtils.movePage(id ? `./?page=${id}` : `./`, '_self');
            }
        }
    };
</script>

<style scoped>
.tool-item-box{
    position: relative;
    background: #333;
    margin: 0.5em 10px;
    padding: 0.5em;
    border: 4px solid #b3b3b3;
    box-shadow: 2px 2px 4px #999, 2px 2px 2px #020 inset;
    cursor: pointer;
}

.tool-item-box:before {
    position: absolute;
    top: calc(-12px);
    left: 0px;
    height: 8px;
    width: calc(100% - 4px);
    background-color: #b3b3b3;
    border-top: 2px solid #666;
    border-left: 2px solid #666;
    border-right: 2px solid #666;
    content: "";
}

.tool-item-box:hover{
    background: #666;
}

.tool-item-box p{
    margin: 0;
    padding: 0;
    color: #fff;
    text-shadow: 0 0 2px #fff;
    user-select: none;
}
</style>