<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'CSS整形'" :desc="'CSS文字列の「整形」または「圧縮」を行うことができます。'"/>

            <div class="card flex justify-content-between">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="整形" icon="pi pi-pencil" @click="onClean" severity="secondary" outlined />
                    <Button type="button" label="圧縮" icon="pi pi-window-minimize" @click="onMinify" severity="secondary" outlined />
                </div>
                <div class="card flex justify-content-end gap-2 py-2 px-2">
                    <Button type="button" v-tooltip.bottom="'クリップボードにコピー'" icon="pi pi-clone" @click="onCopy" severity="secondary" outlined />
                </div>
            </div>

            <div class="card flex justify-content-left gap-2 py-2">
                <!-- <Textarea v-model="selectedValue" autoResize rows="10" cols="30" maxlength="4000" placeholder="最大4000文字まで入力できます"
                    class=" w-full"
                    @input="onInputValue" /> -->
                <Textarea v-model="selectedValue" autoResize rows="10" cols="30" class=" w-full" @input="onInputValue" />
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxSitemapPanel/>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxSitemapPanel from '@/ux/panel/UxSitemapPanel';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';
    import Textarea from 'primevue/textarea';

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxSitemapPanel,
            UxFooterPanel,
            Button,
            Textarea
        },
        props: {
        },
        data() {
            return {
                selectedValue: ''
            };
        },
        computed: {
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {

            /**
             * "値入力"イベント時処理
             */
            onInputValue() {
            },

            /**
             * "整形"イベント時処理
             */
            onClean() {
                const me = this;
                this.$Service.CleanCssService.cleanCss(this.selectedValue).then(function(result) {
                    me.selectedValue = result;
                });
            },

            /**
             * "圧縮"イベント時処理
             */
            onMinify() {
                const me = this;
                this.$Service.CleanCssService.minifyCss(this.selectedValue).then(function(result) {
                    me.selectedValue = result;
                });
            },

            /**
             * "コピー"イベント時処理
             */
            onCopy() {
                navigator.clipboard.writeText(this.selectedValue);
            }
        }
    };
</script>

<style scoped>
</style>