<template>
    <UxMainPanel>
        <template #content>
            <div class="main-panel width-responsive p-4">
                <CleanSqlPanel v-if="menuSelectedMode === 'clean-sql'"/>
                <CleanJsonPanel v-if="menuSelectedMode === 'clean-json'"/>
                <CleanXmlPanel v-if="menuSelectedMode === 'clean-xml'"/>
                <CleanJsPanel v-if="menuSelectedMode === 'clean-js'"/>
                <CleanCssPanel v-if="menuSelectedMode === 'clean-css'"/>
                <CleanHtmlPanel v-if="menuSelectedMode === 'clean-html'"/>
                <ConvertPngToJpgPanel v-if="menuSelectedMode === 'convert-png-to-jpg'"/>
                <ConvertJpgToPngPanel v-if="menuSelectedMode === 'convert-jpg-to-png'"/>
                <ConvertSvgToPngPanel v-if="menuSelectedMode === 'convert-svg-to-png'"/>
                <ConvertSvgToJpgPanel v-if="menuSelectedMode === 'convert-svg-to-jpg'"/>
                <ConvertPngToTransparentPanel v-if="menuSelectedMode === 'convert-png-to-transparent'"/>
                <ConvertPngChangeColorPanel v-if="menuSelectedMode === 'convert-png-change-color'"/>
                <ColorConvertPanel v-if="menuSelectedMode === 'color-conert-rgb-hex'"/>
                <ColorGradationPanel v-if="menuSelectedMode === 'color-gradation'"/>
                <LinkFreeImagePanel v-if="menuSelectedMode === 'links-free-image'"/>
                <LinkFreeMusicPanel v-if="menuSelectedMode === 'links-free-music'"/>
                <LinkCreateLogoPanel v-if="menuSelectedMode === 'links-create-logo'"/>
                <HomePanel v-if="!menuSelectedMode || menuSelectedMode === '' || ![
                    'clean-sql','clean-json','clean-xml','clean-js','clean-css','clean-html',
                    'convert-jpg-to-png','convert-png-to-jpg','convert-svg-to-png','convert-svg-to-jpg','convert-png-to-transparent','convert-png-change-color',
                    'color-conert-rgb-hex','color-gradation',
                    'links-free-image','links-free-music','links-create-logo'
                ].includes(menuSelectedMode)" ref="homePanel"/>
            </div>
        </template>
    </UxMainPanel>
</template>

<script>
    import UxMainPanel from "@/ux/frame/UxMainPanel";
    import HomePanel from "@/views/view/HomePanel";
    import CleanSqlPanel from "@/views/contents/CleanSqlPanel";
    import CleanJsonPanel from "@/views/contents/CleanJsonPanel";
    import CleanXmlPanel from "@/views/contents/CleanXmlPanel";
    import CleanJsPanel from "@/views/contents/CleanJsPanel";
    import CleanCssPanel from "@/views/contents/CleanCssPanel";
    import CleanHtmlPanel from "@/views/contents/CleanHtmlPanel";
    import ConvertPngToJpgPanel from "@/views/contents/ConvertPngToJpgPanel";
    import ConvertJpgToPngPanel from "@/views/contents/ConvertJpgToPngPanel";
    import ConvertSvgToPngPanel from "@/views/contents/ConvertSvgToPngPanel";
    import ConvertSvgToJpgPanel from "@/views/contents/ConvertSvgToJpgPanel";
    import ConvertPngToTransparentPanel from "@/views/contents/ConvertPngToTransparentPanel";
    import ConvertPngChangeColorPanel from "@/views/contents/ConvertPngChangeColorPanel";
    import ColorConvertPanel from "@/views/contents/ColorConvertPanel";
    import ColorGradationPanel from "@/views/contents/ColorGradationPanel";
    import LinkFreeImagePanel from "@/views/contents/LinkFreeImagePanel";
    import LinkFreeMusicPanel from "@/views/contents/LinkFreeMusicPanel";
    import LinkCreateLogoPanel from "@/views/contents/LinkCreateLogoPanel";

    export default {
        components: {
            UxMainPanel,
            HomePanel,
            CleanSqlPanel,
            CleanJsonPanel,
            CleanXmlPanel,
            CleanJsPanel,
            CleanCssPanel,
            CleanHtmlPanel,
            ConvertPngToJpgPanel,
            ConvertJpgToPngPanel,
            ConvertSvgToPngPanel,
            ConvertSvgToJpgPanel,
            ConvertPngToTransparentPanel,
            ConvertPngChangeColorPanel,
            ColorConvertPanel,
            ColorGradationPanel,
            LinkFreeImagePanel,
            LinkFreeMusicPanel,
            LinkCreateLogoPanel
        },
        props: {
            menuSelectedMode: {
                type: String,
                default: null
            }
        },
        data() {
            return {
            };
        },
        watch: {
        },
        computed: {
        },
        methods: {
        }
    };
</script>

<style scoped>
.main-panel .width-responsive {
    width: 75%;
}

@media (max-width: 1024px) {
    .main-panel .width-responsive {
        width: 100%;
    }
    .main-panel .width-responsive {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .main-panel::-webkit-scrollbar {
        display: none;
    }
}
</style>