import { html as beautifyHtml } from 'js-beautify';
import Minimize from 'minimize';


/**
 * HTML整形サービス
 */
export default class CleanHtmlService {

    /** サービス */
    services = {
        CleanJsService: null,
        CleanCssService: null,
    };

    /**
     * サービス初期化
     * @param {Object} services サービス
     */
    initializeService(services={}) {
        this.services = services;
    }

    /**
     * HTML整形
     * @param {String} value 値
     * @returns {String} 整形後値
     */
    async cleanHtml(value='') {
        // CSSを整形
        const cleand = beautifyHtml(value, {
            indent_size: 4,  // インデントのサイズ
            preserve_newlines: true,  // 改行を保持する
            max_preserve_newlines: 1,  // 最大改行数
            wrap_line_length: 0,  // 行の折り返し
            end_with_newline: true,  // ファイルの最後に改行を追加
            indent_inner_html: true,  // 内部のHTMLをインデント
            unformatted: [],  // 整形しないタグのリスト
            extra_liners: ['head', 'body', '/html']  // 追加の改行を入れるタグ
        });
        return cleand;
    }

    /**
     * HTML圧縮
     * @param {String} value 値
     * @returns {String} 圧縮後値
     */
    async minifyHtml(value='') {
        const minimize = new Minimize();
        let minified = await minimize.parse(value, {
            minify: true,
            html5: true,
        });
        const styleTags = [];
        const scriptTags = [];
        let stylePos = -1;
        let scriptPos = -1;
        for (let i = 0; i < minified.length; i++) {
            if (minified.substring(i).startsWith('<style>') && stylePos < 0) {
                stylePos = i;
            }
            if (minified.substring(i).startsWith('</style>') && stylePos >= 0) {
                styleTags.push(minified.substring(stylePos + '<style>'.length, i));
                stylePos = -1;
            }
            if (minified.substring(i).startsWith('<script>') && scriptPos < 0) {
                scriptPos = i;
            }
            if (minified.substring(i).startsWith('</script>') && scriptPos >= 0) {
                scriptTags.push(minified.substring(scriptPos + '<script>'.length, i));
                scriptPos = -1;
            }
        }
        for (const styleTag of styleTags) {
            const minifiedCSS = await this.services.CleanCssService.minifyCss(styleTag);
            minified = minified.replaceAll(styleTag, minifiedCSS);
        }
        for (const scriptTag of scriptTags) {
            const minifiedJS = await this.services.CleanJsService.minifyJs(scriptTag);
            minified = minified.replaceAll(scriptTag, minifiedJS);
        }
        return minified;
    }

};
