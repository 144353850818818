<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'商用フリー音声'" :desc="'商用フリー音声を取り扱うサイトをまとめています。'"/>

            <div class="card my-2">
                <div v-for="(link) of links" :key="link.href">
                    <a :href="link.href" class="link-card p-card p-shadow-2 no-underline">
                        <div class="flex overflow-hidden my-5 py-2 px-2 border-1 border-round">
                            <div class="flex-none flex align-items-center justify-content-center">
                                <iframe
                                    style="width:200px;height:100%;max-width:200px;"
                                    :title="link.title"
                                    :src="link.href"
                                    frameborder="0" scrolling="no" allow="fullscreen">
                                </iframe>
                            </div>
                            <div class="flex-grow-1 flex align-items-center justify-content-start">
                                <div class="card w-full">
                                    <div class="flex flex-wrap">
                                        <p class="text-2xl text-overflow-ellipsis mt-2 ml-3">{{link.title}}</p>
                                    </div>
                                    <div class="flex flex-wrap">
                                        <p class="text-lg white-space-normal text-overflow-ellipsis mt-2 ml-5" style="word-break: break-all;">{{link.desc}}</p>
                                    </div>
                                    <div class="flex align-items-end justify-content-end">
                                        {{'移動 >'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxSitemapPanel/>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxSitemapPanel from '@/ux/panel/UxSitemapPanel';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxSitemapPanel,
            UxFooterPanel
        },
        props: {
        },
        data() {
            return {
                links: [
                    {
                        href: 'https://www.springin.org/sound-stock/',
                        title: '無料 BGM・効果音のフリー音源素材 | Springin’ Sound Stock',
                        desc: '商用利用可能のBGM・効果音が1000点以上！動画やゲーム開発、イベントに使える音素材を無料公開しています。スプリンギンでのゲーム制作にも使用可能です。'
                    },
                    {
                        href: 'https://soundeffect-lab.info/',
                        title: '効果音ラボ - 商用無料、報告不用の効果音素材をダウンロード',
                        desc: '2,000音以上の音源を掲載したフリー効果音サイト。品質にこだわっており、テレビなどのプロの音響現場でも使われています。'
                    }
                ]
            };
        },
        computed: {
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
        }
    };
</script>

<style scoped>
</style>