import { format } from 'sql-formatter';

/**
 * SQL整形サービス
 */
export default class CleanSqlService {

    /**
     * DB種別
     */
    /** sql */
    static ST_SQL = 'sql';
    /** mysql */
    static ST_MYSQL = 'mysql';

    /**
     * SQL整形
     * @param {String} value 値
     * @returns {String} 整形後値
     */
    async cleanSql(value, language=CleanSqlService.ST_SQL) {
        const cleand = format(value, {
            language: language,
            // useTabs: true,
            tabWidth: 4,
            keywordCase: 'upper',
            linesBetweenQueries: 2,
        });
        return cleand;
    }

    /**
     * SQL圧縮
     * @param {String} value 値
     * @returns {String} 圧縮後値
     */
    async minifySql(value='') {
        let minified = value;
        minified = minified.replaceAll('\r\n', ' ');
        minified = minified.replaceAll('\n', ' ');
        minified = minified.replaceAll('\t', '');
        minified = minified.replace(/\s+/g, ' '); // 連続する空白を1つにまとめる
        return minified;
    }

};
