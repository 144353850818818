import { createApp } from 'vue';
import { Vue3Mq } from "vue3-mq";

import App from './App.vue'
import Router from './router';
import PrimeVue from "primevue/config";

// 日本語設定
import ja from "@/i18n/locale/ja";

// テーマ
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/themes/vela-green/theme.css";

// スタイル
import "primevue/resources/primevue.min.css"; // ベース
import "primeicons/primeicons.css"; // アイコン
import "primeflex/primeflex.css"; // primeflex

// アプリケーション
import AppService from "@/app/service/AppService.mjs";
import CleanSqlService from "@/app/service/CleanSqlService.mjs";
import CleanXmlService from "@/app/service/CleanXmlService.mjs";
import CleanJsonService from "@/app/service/CleanJsonService.mjs";
import CleanJsService from "@/app/service/CleanJsService.mjs";
import CleanCssService from "@/app/service/CleanCssService.mjs";
import CleanHtmlService from "@/app/service/CleanHtmlService.mjs";
import ConvertPngService from "@/app/service/ConvertPngService.mjs";
import ConvertJpgService from "@/app/service/ConvertJpgService.mjs";
import ConvertSvgService from "@/app/service/ConvertSvgService.mjs";

// スタイル
const app = createApp(App);

// ライブラリ登録
app.use(Router);
app.use(PrimeVue, { locale: ja });
app.use(Vue3Mq, { preset: "vuetify" });

// ツールチップ有効化
import Tooltip from 'primevue/tooltip';
app.directive('tooltip', Tooltip);

// 設定ファイル読込
import Settings from "@/assets/Settings.json";

// グローバル変数を定義
app.config.globalProperties.$Settings = Settings;
app.config.globalProperties.$Service = {
    AppService: new AppService(),
    CleanSqlService: new CleanSqlService(),
    CleanXmlService: new CleanXmlService(),
    CleanJsonService: new CleanJsonService(),
    CleanJsService: new CleanJsService(),
    CleanCssService: new CleanCssService(),
    CleanHtmlService: new CleanHtmlService(),
    ConvertPngService: new ConvertPngService(),
    ConvertJpgService: new ConvertJpgService(),
    ConvertSvgService: new ConvertSvgService()
};
app.config.globalProperties.$Service.CleanHtmlService.initializeService({
    CleanJsService: app.config.globalProperties.$Service.CleanJsService,
    CleanCssService: app.config.globalProperties.$Service.CleanCssService,
});
app.config.globalProperties.$Menus = [
    {
        code: "clean",
        name: "整形",
        items: [
            { code:'clean-sql', name: 'SQL整形' },
            { code:'clean-json', name: 'JSON整形' },
            { code:'clean-xml', name: 'XML整形' },
            { code:'clean-html', name: 'HTML整形' },
            { code:'clean-js', name: 'JS整形' },
            { code:'clean-css', name: 'CSS整形' },
        ]
    },
    {
        code: "image",
        name: "加工",
        items: [
            { code:'convert-png-to-jpg', name: 'PNG to JPG' },
            { code:'convert-jpg-to-png', name: 'JPG to PNG' },
            { code:'convert-svg-to-png', name: 'SVG to PNG' },
            { code:'convert-svg-to-jpg', name: 'SVG to JPG' },
            { code:'convert-png-to-transparent', name: 'PNG透過' },
            { code:'convert-png-change-color', name: 'PNG色変更' },
        ]
    },
    {
        code: "color",
        name: "色",
        items: [
            { code:'color-conert-rgb-hex', name: 'RGB／16進数変換' },
            { code:'color-gradation', name: 'グラデーション生成' },
        ]
    },
    // {
    //     code: "edit",
    //     name: "編集",
    //     items: [
    //         { code:'edit-markdown', name: 'Markdown編集' },
    //     ]
    // },
    // {
    //     code: "calculation",
    //     name: "計算",
    //     items: [
    //         { code:'calculation-coordinate', name: '座標系変換' },
    //     ]
    // },
    {
        code: "material",
        name: "素材",
        items: [
            { code:'links-free-image', name: '商用フリー画像' },
            { code:'links-free-music', name: '商用フリー音声' },
            { code:'links-create-logo', name: 'ロゴ／アイコン作成' },
        ]
    }
];

// マウント
app.mount('#app');